import * as THREE from "three";
import GUI from "lil-gui";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import colorTextureImg from "./textures/door/color2.jpg";
import alphaTextureImg from "./textures/door/alpha.jpg";
import heightTextureImg from "./textures/door/height.png";
import normalTextureImg from "./textures/door/normal.jpg";
import ambientOcclusionTextureImg from "./textures/door/ambientOcclusion.jpg";
import metalnessTextureImg from "./textures/door/metalness.jpg";
import roughnessTextureImg from "./textures/door/roughness.jpg";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";

const PixelRatio = 2;

/*
const textureLoader = new THREE.TextureLoader();
const cubeTextureLoader = new THREE.CubeTextureLoader();

const colorTexture = textureLoader.load(colorTextureImg);
const alphaTexture = textureLoader.load(alphaTextureImg);
const heightTexture = textureLoader.load(heightTextureImg);
const normalTexture = textureLoader.load(normalTextureImg);
const ambientOcclusionTexture = textureLoader.load(ambientOcclusionTextureImg);
const metalnessTexture = textureLoader.load(metalnessTextureImg);
const roughnessTexture = textureLoader.load(roughnessTextureImg);
const gradientTexture = textureLoader.load("gradients/5.jpg");
gradientTexture.minFilter = THREE.NearestFilter;
gradientTexture.magFilter = THREE.NearestFilter;

const matcapTexture = textureLoader.load(
  "https://raw.githubusercontent.com/nidorx/matcaps/master/1024/3B3C3F_DAD9D5_929290_ABACA8.png"
);

const environmentMapTexture = cubeTextureLoader.load([
  "/environmentMaps/0/px.jpg",
  "/environmentMaps/0/nx.jpg",
  "/environmentMaps/0/py.jpg",
  "/environmentMaps/0/ny.jpg",
  "/environmentMaps/0/pz.jpg",
  "/environmentMaps/0/nz.jpg",
]);*/

// Canvas
const canvas = document.querySelector("#scene");
const scene = new THREE.Scene();

// Cene
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height);
camera.position.z = 2;
scene.add(camera);

// Lights
const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
const pointLight = new THREE.PointLight(0xffffff, 0.5);
pointLight.position.x = 2;
pointLight.position.y = 3;
pointLight.position.z = 4;
scene.add(ambientLight, pointLight);

// debug

const gui = new GUI();

// axes
const axesHelper = new THREE.AxesHelper();
scene.add(axesHelper);

// Text
const textureLoader = new THREE.TextureLoader();
const matcapTexture = textureLoader.load("/textures/matcaps/6.png");

const fontLoader = new FontLoader();

fontLoader.load("./fonts/helvetiker_regular.typeface.json", (font) => {
  const textGeometry = new TextGeometry("ANGELO", {
    font,
    size: 0.5,
    height: 0.5,
    curveSegments: 5,
    bevelEnabled: true,
    bevelThickness: 0.03,
    bevelSize: 0.02,
    bevelOffset: 0,
    bevelSegments: 5,
  });

  textGeometry.center();

  console.log(textGeometry.boundingBox);
  //console.log(textGeometry);
  //gui.add(textGeometry, "size").min(0).max(1);

  const textMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture });
  const text = new THREE.Mesh(textGeometry, textMaterial);
  scene.add(text);
});

// Objetcs
/*const material = new THREE.MeshBasicMaterial({
  map: colorTexture,
  transparent: true,
  alphaMap: alphaTexture,
  side: THREE.DoubleSide,
});*/

/*const material = new THREE.MeshNormalMaterial({
  flatShading: false,
});

const material = new THREE.MeshMatcapMaterial({
  matcap: matcapTexture,
});

const material = new THREE.MeshDepthMaterial();

const material = new THREE.MeshLambertMaterial();

const material = new THREE.MeshPhongMaterial();

material.shininess = 200;
material.specular = new THREE.Color(0xff0000);

const material = new THREE.MeshToonMaterial();

material.gradientMap = gradientTexture;*/

/*const material = new THREE.MeshStandardMaterial();
material.metalness = 0.7;
material.roughness = 0.2;
material.map = colorTexture;
material.aoMap = ambientOcclusionTexture;
material.aoMapIntensity = 1;
material.displacementMap = heightTexture;
material.displacementScale = 0.05;
material.metalnessMap = metalnessTexture;
material.roughnessMap = roughnessTexture;
material.normalMap = normalTexture;
material.transparent = true;
material.alphaMap = alphaTexture;
material.envMap = environmentMapTexture;

gui.add(material, "metalness").min(0).max(1);
gui.add(material, "roughness").min(0).max(1);
gui.add(material, "aoMapIntensity").min(0).max(10);
gui.add(material, "displacementScale").min(0).max(1);


// Sphere
const sphere = new THREE.Mesh(new THREE.SphereGeometry(0.5, 64, 64), material);
sphere.position.x = -1.5;
sphere.geometry.setAttribute(
  "uv2",
  new THREE.BufferAttribute(sphere.geometry.attributes.uv.array, 2)
);

// Plane
const plane = new THREE.Mesh(new THREE.PlaneGeometry(1, 1, 100, 100), material);
plane.geometry.setAttribute(
  "uv2",
  new THREE.BufferAttribute(plane.geometry.attributes.uv.array, 2)
);

// Torus
const torus = new THREE.Mesh(
  new THREE.TorusGeometry(0.3, 0.2, 64, 128),
  material
);
torus.position.x = 1.5;
torus.geometry.setAttribute(
  "uv2",
  new THREE.BufferAttribute(torus.geometry.attributes.uv.array, 2)
);

scene.add(sphere, plane, torus);*/

// Orbital control
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;
controls.target.z = 0;

const renderer = new THREE.WebGLRenderer({
  canvas,
});

renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(PixelRatio);
renderer.render(scene, camera);

const clock = new THREE.Clock();

const animate = () => {
  const elapsedTime = clock.getElapsedTime();

  // update objects
  /*sphere.rotation.y = 0.1 * elapsedTime;
  plane.rotation.y = 0.1 * elapsedTime;
  torus.rotation.y = 0.1 * elapsedTime;

  sphere.rotation.x = 0.15 * elapsedTime;
  plane.rotation.x = 0.15 * elapsedTime;
  torus.rotation.x = 0.15 * elapsedTime;*/

  controls.update();

  renderer.render(scene, camera);
  window.requestAnimationFrame(animate);
};

animate();
